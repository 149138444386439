import axios from "lib/axios";
import moment from "moment";

export const getCompanyInfo = () => {
  return axios.get(`/stats/company/info`);
};

export const getCompanyInfoDay = (day) => {
  return axios.get(`/stats/company/`, {
    params: {
      at: day,
      fmt: "day",
    },
  });
};

export const getCompanyInfoToday = () => {
  const today = moment.utc().format("YYYY-MM-DD");
  return getCompanyInfoDay(today);
};

export const getCompanyInfoMonth = (month) => {
  return axios.get(`/stats/company/day/${month}`);
};

export const getCompanyInfoAlltime = () => {
  return axios.get(`/stats/company?fmt=alltime`, {
    params: {
      fmt: "alltime",
    },
  });
};

export const getCompanyInfoDaily = () => {
  const startDate = moment().utc().subtract(6, "days").format("YYYY-MM-D");
  const endDate = moment().utc().format("YYYY-MM-D");

  return axios.get(`/stats/company/`, {
    params: {
      from: startDate,
      to: endDate,
      fmt: "day",
    },
  });
};

export const getCompanyInfoWeekly = () => {
  const startDate = moment().utc().subtract(3, "weeks").format("YYYY-WW");
  const endDate = moment().utc().format("YYYY-WW");

  return axios.get(`/stats/company/`, {
    params: {
      from: startDate,
      to: endDate,
      fmt: "week",
    },
  });
};

export const getCompanyInfoMonthly = () => {
  const startDate = moment().utc().subtract(5, "months").format("YYYY-MM");
  const endDate = moment().utc().format("YYYY-MM");

  return axios.get(`/stats/company/`, {
    params: {
      from: startDate,
      to: endDate,
      fmt: "month",
    },
  });
};

export const getCompanyInfoQuarterly = () => {
  const startDate = moment().utc().subtract(3, "quarters").format("YYYY-QQ");
  const endDate = moment().utc().format("YYYY-QQ");

  return axios.get(`/stats/company/`, {
    params: {
      from: startDate,
      to: endDate,
      fmt: "quarter",
    },
  });
};

export const getCompanyInfoYearly = () => {
  const startDate = moment().utc().subtract(5, "years").format("YYYY");
  const endDate = moment().utc().format("YYYY");

  return axios.get(`/stats/company/?from=${startDate}&to=${endDate}&fmt=year`, {
    params: {
      from: startDate,
      to: endDate,
      fmt: "year",
    },
  });
};
