const StatusCodes = {
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

const CSRF_ERROR_CODE = 103;

export const makeError = (name, data = {}) => Promise.reject({ name, ...data });

export const unauthorized = (message) =>
  makeError("AuthError", { status: StatusCodes.UNAUTHORIZED, message });

export const clientError = (status, data = {}) =>
  makeError("ClientError", { status, ...data });

export const csrfError = (status, data = {}) =>
  makeError("CsrfError", { status, ...data });

export const unknownError = (status) => makeError("UnknownError", { status });

const errorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    const {
      response: { status, data },
    } = error;
    const { message } = data;

    if (status === StatusCodes.UNAUTHORIZED) {
      if (message?.code === CSRF_ERROR_CODE) {
        return csrfError(status, { message });
      }
      return unauthorized(data);
    }
    if (
      status === StatusCodes.BAD_REQUEST ||
      status === StatusCodes.FORBIDDEN ||
      status === StatusCodes.NOT_FOUND
    ) {
      const { message } = data;
      return clientError(status, { message });
    }
    return unknownError(status);
  }

  return Promise.reject(error);
};

export default errorHandler;
