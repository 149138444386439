import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/shares/sharelinks.constants";
import { combineReducers } from "redux";

import pagination from "../pagination";
import { getItemsById } from "../utils";
import {makeListReducer} from "../list";

const initialListState = {
  loading: false,
  ready: false,
  error: false,
  items: [],
  itemsById: {},
  count: 0,
  allSelected: false,
  anySelected: false,
  columns: {
    topic: true,
    holders: true,
    created: true,
    hide_questions: true,
    num_users: true,
    num_total_certificates: true,
    training_minutes: true,
  },
};

const sharelinksReducer = (state = initialListState, action) => {
  switch (action.type) {
    case constants.SHARELINKS.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
        error: false,
      };
    case constants.SHARELINKS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
        ready: false,
        error: true,
      };
    case constants.SHARELINKS.SUCCESS: {
      const { results: items, count } = action.data;
      return {
        ...state,
        count,
        loading: false,
        ready: true,
        allSelected: false,
        anySelected: false,
        items: items,
        itemsById: getItemsById(items),
      };
    }
    case constants.SELECT: {
      let { items: shareLinks } = state;
      shareLinks = shareLinks.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items: shareLinks,
        itemsById: getItemsById(shareLinks),
        allSelected: shareLinks.every((it) => it.selected === true),
        anySelected: shareLinks.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items: shareLinks, allSelected } = state;
      allSelected = !allSelected;
      shareLinks = shareLinks.map((it) => {
        it.selected = allSelected;
        return it;
      });
      return {
        ...state,
        items: shareLinks,
        itemsById: getItemsById(shareLinks),
        allSelected,
        anySelected: shareLinks.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialListState;
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  ready: false,
  error: false,
  topic: null,
  request_data: false,
  hide_questions: false,
};

const sharelinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SHARELINK_CREATE.REQUEST:
    case constants.SHARELINK_GET.REQUEST:
    case constants.SHARELINK_UPDATE.REQUEST:
    case constants.SHARELINK_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
        error: false,
      };
    case constants.SHARELINK_CREATE.FAILURE:
    case constants.SHARELINK_GET.FAILURE:
    case constants.SHARELINK_UPDATE.FAILURE:
    case constants.SHARELINK_DELETE.FAILURE:
      return {
        ...state,
        loading: false,
        ready: false,
        error: true,
      };
    case constants.SHARELINK_CREATE.SUCCESS:
    case constants.SHARELINK_GET.SUCCESS:
    case constants.SHARELINK_UPDATE.SUCCESS:
    case constants.SHARELINK_DELETE.SUCCESS: {
      const data = action.data;
      return {
        ...data,
        loading: false,
        ready: true,
      };
    }
    case constants.SHARELINK_RESET:
      return {
        ...initialState,
      };
    case UNAUTHORIZED:
      return initialListState;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "-created",
});

export default combineReducers({
  list: makeListReducer(sharelinksReducer, "sharelinks"),
  pagination: paginationReducer,
  current: sharelinkReducer,
});
