import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/questionAnalytics.constants.js";
import reduceReducers from "reduce-reducers";
import pagination from "reducers/pagination";
import { combineReducers } from "redux";

import { getItemsById } from "./utils";
import { makeListReducer } from "./list";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  isPanelShown: false,
  columns: {
    category_name: true,
    title: true,
    status: true,
    misconception_score: true,
    complexity_score: true,
    obviousness_score: true,
    content_error_score: true,
    num_users_seen: true,
    num_users: true,
  },
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.QUESTIONS_ANALYTICS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QUESTIONS_ANALYTICS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.QUESTIONS_ANALYTICS.SUCCESS: {
      const { count, results: questions } = action.data;
      return {
        ...state,
        items: questions,
        itemsById: getItemsById(questions),
        count: count,
        loading: false,
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const initialQuestionState = {
  found: false,
  error: false,
  loading: false,

  title_html: "",
  answers: [
    { id: 0, text_html: "", correct: false },
    { id: 1, text_html: "", correct: false },
    { id: 2, text_html: "", correct: false },
    { id: 3, text_html: "", correct: false },
  ],
  image: null,
  tip: {
    title_html: "",
    text_html: "",
    image: null,
  },
  tags: [],
};

const currentQuestionReducer = (state = initialQuestionState, action) => {
  switch (action.type) {
    case constants.QUESTION_ANALYTICS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.QUESTION_ANALYTICS.FAILURE:
      return {
        ...state,
        found: false,
        error: true,
        loading: false,
      };
    case constants.QUESTION_ANALYTICS.SUCCESS: {
      /**
       * @param {number} a.expert_answer_contribution
       * @param {number} b.expert_answer_contribution
       * @param {number} x.answer_contribution
       */
      const question = action.data;
      const { answers } = question;
      const hasExpertAnalytics = answers.some(
        (i) => i.expert_answer_contribution !== 0,
      );

      let expertChosenAnswer;

      if (hasExpertAnalytics) {
        expertChosenAnswer = answers.reduce((left, right) => {
          const leftContribution = left ? left.expert_answer_contribution : 0;
          const rightContribution = right
            ? right.expert_answer_contribution
            : 0;

          return leftContribution < rightContribution ? right : left;
        }, null);
      }

      let diff = 0;

      let newAnswers = answers.map((it) => {
        const isExpertChosen =
          expertChosenAnswer && it.id === expertChosenAnswer.id;
        const userContributionPercent = Math.floor(
          it.answer_contribution * 100,
        );
        const expertContributionPercent = hasExpertAnalytics
          ? Math.floor(it.expert_answer_contribution * 100)
          : null;

        diff += (it.answer_contribution * 100) % 1;

        return {
          ...it,
          is_expert_chosen: isExpertChosen,
          answer_contribution_percent: userContributionPercent,
          expert_answer_contribution_percent: expertContributionPercent,
        };
      });

      if (diff) {
        diff = Math.floor(diff);

        newAnswers.sort((a, b) =>
          (a.answer_contribution * 100) % 1 > (b.answer_contribution * 100) % 1
            ? -1
            : 1,
        );
        newAnswers = newAnswers
          .map((it) => {
            const increase = diff > 0 ? 1 : 0;
            diff = diff - increase;

            return {
              ...it,
              answer_contribution_percent:
                it.answer_contribution_percent + increase,
            };
          })
          .sort((a, b) =>
            a.answer_contribution_percent > b.answer_contribution_percent
              ? -1
              : 1,
          );
      }

      return {
        ...state,
        ...{ ...question, answers: newAnswers },
        found: true,
        error: false,
        loading: false,
      };
    }
    case constants.QUESTION_ANALYTICS_RESET:
      return {
        ...initialQuestionState,
        found: false,
        error: false,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const extraPaginationReducer = (
  state = {
    page: 0,
    pagesize: 10,
    orderby: "category_name",
    search: "",
    extra_filters: [],
    show_all: "false",
    category: null,
    language: null,
  },
  action,
) => {
  switch (action.type) {
    case constants.PAGINATION.SHOW_ALL:
      return {
        ...state,
        page: 0,
        show_all: action.data,
      };
    case constants.PAGINATION.CATEGORY:
      return {
        ...state,
        page: 0,
        category: action.data,
      };
    case constants.PAGINATION.GROUP:
      return {
        ...state,
        page: 0,
        group: action.data,
      };
    case constants.PAGINATION.LANGUAGE:
      return {
        ...state,
        page: 0,
        language: action.data,
      };
    default:
      return state;
  }
};

const basePaginationReducer = pagination(constants.PAGINATION, {
  page: 0,
  pagesize: 10,
  orderby: "category_name",
  search: "",
  extra_filters: [],
  show_all: "false",
  category: null,
  language: null,
});

const paginationReducer = reduceReducers(
  extraPaginationReducer,
  basePaginationReducer,
);

export default combineReducers({
  list: makeListReducer(questionsReducer, "analytics"),
  pagination: paginationReducer,
  currentQuestion: currentQuestionReducer,
});
