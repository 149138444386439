import axios from "lib/axios";

import { fetchAll } from "./utils";

export const getUsers = (params) => {
  return axios.get(`/users/`, { params });
};

export const getUser = (userId) => {
  return axios.get(`/users/${userId}`);
};

export const updateUser = (userId, data) => {
  return axios.put(`/users/${userId}`, data);
};

export const getUserTeams = (userId, params) => {
  return axios.get(`/users/${userId}/teams/`, {
    params,
  });
};
export const getUserTeamsAll = (userId) => {
  return fetchAll((args) => getUserTeams(userId, args));
};

export const getUserCerts = (userId, params) => {
  return axios.get(`/users/${userId}/certificates/`, { params });
};

export const getUserCertsAll = (userId) => {
  return fetchAll((args) => getUserCerts(userId, args));
};

export const getUserCertsInfo = (userId) => {
  return axios.get(`/users/${userId}/certificates/info`);
};

export const getUserTrainings = (userId, params) => {
  return axios.get(`/users/${userId}/trainings/`, { params });
};

export const getUserTrainingsAll = (userId) => {
  return fetchAll((args) => getUserTrainings(userId, args));
};

export const getUserCompliance = (userId, params) => {
  return axios.get(`/users/${userId}/compliances/`, { params });
};

export const getUserComplianceAll = (userId) => {
  return fetchAll((args) => getUserCompliance(userId, args));
};

export const setUserRoles = (userId, role) => {
  return updateUser(userId, { role });
};

export const enableUser = (userId) => {
  return axios.put(`/users/${userId}/enable`);
};

export const sendConfirmation = (userId) => {
  return axios.post(`/users/${userId}/send_confirmation`);
};

export const requestConfirmationLink = (userId) => {
  return axios.post(`/users/${userId}/activate`);
};

export const disableUser = (userId) => {
  return axios.put(`/users/${userId}/disable`);
};

export const deleteUser = (userId) => {
  return axios.delete(`/users/${userId}`);
};

export const bulkEnableUsers = (userIds) => {
  return axios.post(`/users/bulk/enable`, { ids: userIds });
};

export const requestPasswordReset = (userId, data) => {
  return axios.post(`/users/${userId}/password_reset`, data);
};

export const bulkDisableUsers = (userIds) => {
  return axios.post(`/users/bulk/disable`, { ids: userIds });
};

export const bulkDeleteUsers = (userIds) => {
  return axios.post(`/users/bulk/delete`, { ids: userIds });
};

export const getUserCertificates = (userId, params) => {
  return axios.get(`/users/${userId}/certificates/download`, {
    params: { ...params, format: "pdf" },
  });
};
