import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/groups/groups.constants";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

import pagination from "../pagination";
import { getItemsById } from "../utils";
import groupTopicsReducer from "./groupTopics";
import groupTrainingsReducer from "./groupTrainings";
import groupUsersReducer from "./groupUsers";
import {makeListReducer} from "../list";

const groupsState = {
  loading: false,
  ready: false,
  error: null,

  items: [],
  itemsById: {},
  count: 0,
  allSelected: false,
  anySelected: false,
  columns: {
    group: true,
    description: true,
    users: true,
    topics: true,
  },

  showMoveModal: false,
};

const updateNested = (data, itemId, update) => {
  if (Array.isArray(data)) {
    return data.map((it) => updateNested(it, itemId, update));
  }

  let updated = { ...data };
  if ((!itemId && data?.id) || data?.id === itemId) {
    updated = { ...data, ...update };
  }
  if (updated?.children?.results?.length) {
    updated.children.results = updated.children.results.map((it) =>
      updateNested(it, itemId, update),
    );
  }
  return updated;
};

const getNested = (data, groupId) => {
  let _toIterate = Array.isArray(data) ? [...data] : [data];

  while (_toIterate.length) {
    const currentItem = _toIterate.shift();
    if (currentItem?.id === groupId) {
      return currentItem;
    }
    if (currentItem?.children?.results) {
      _toIterate = [..._toIterate, ...currentItem.children.results];
    }
  }
  return null;
};

const groupsReducer = (state = groupsState, action) => {
  switch (action.type) {
    case constants.GROUPS.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
        error: false,
      };
    case constants.GROUPS.FAILURE:
      return {
        ...state,
        loading: false,
        ready: false,
        error: true,
        items: [],
        itemsById: {},
      };
    case constants.GROUPS.SUCCESS: {
      const { count, results: groups } = action.data;
      return {
        ...state,
        items: groups,
        itemsById: getItemsById(groups),
        count: count,
        loading: false,
        ready: true,
        error: false,
        allSelected: false,
        anySelected: false,
      };
    }
    case constants.SELECT: {
      let { items: groups } = state;
      const selectedId = action.data.id;
      let allSelected = true;
      let anySelected = false;

      const selectNested = (it) => {
        const hasChildren = it?.children?.results;

        if (it.id === selectedId) {
          it.selected = !it.selected;
          if (hasChildren) {
            it.children.results = updateNested(it.children.results, null, {
              selected: false,
              selectedIndirectly: it.selected,
            });
          }
        } else if (hasChildren) {
          it.children.results = it.children.results.map(selectNested);
        }

        if (it.selected) {
          anySelected = true;
        } else {
          allSelected = false;
        }
        return it;
      };

      groups = groups.map(selectNested);

      return {
        ...state,
        items: groups,
        itemsById: getItemsById(groups),
        allSelected: allSelected,
        anySelected: anySelected,
      };
    }
    case constants.SELECT_ALL: {
      let { items: groups, allSelected } = state;
      allSelected = !allSelected;
      groups = groups.map((it) => {
        it.selected = allSelected;
        if (it?.children?.results) {
          it.children.results = updateNested(it.children.results, null, {
            selected: false,
            selectedIndirectly: true,
          });
        }
        return it;
      });

      return {
        ...state,
        items: groups,
        itemsById: getItemsById(groups),
        allSelected,
        anySelected: groups.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case constants.GROUPS_TOGGLE_MOVE_MODAL: {
      const modalState = action.data;
      return {
        ...state,
        showMoveModal: modalState,
      };
    }
    case constants.GROUP_TREE_UPDATE.REQUEST: {
      const groupId = action.data;
      const { items: groups } = state;

      const updatedGroups = updateNested(groups, groupId, { loading: true });

      return {
        ...state,
        items: updatedGroups,
        itemsById: getItemsById(updatedGroups),
      };
    }
    case constants.GROUP_TREE_UPDATE.SUCCESS: {
      const { id: groupId, data } = action.data;
      const { items: groups } = state;

      let group = getNested(groups, groupId);
      group = { ...group, ...data };

      if (
        group?.children?.results &&
        (group.selected || group.selectedIndirectly)
      ) {
        group.children.results = updateNested(group.children.results, null, {
          selected: false,
          selectedIndirectly: true,
        });
      }

      const updatedGroups = updateNested(groups, groupId, {
        loading: false,
        ...group,
      });

      return {
        ...state,
        items: updatedGroups,
        itemsById: getItemsById(updatedGroups),
      };
    }
    case constants.GROUP_TREE_UPDATE.FAILURE: {
      const { groupId } = action.data;
      const { items: groups } = state;

      const updatedGroups = updateNested(groups, groupId, { loading: false });

      return {
        ...state,
        items: updatedGroups,
        itemsById: getItemsById(updatedGroups),
      };
    }
    case constants.COLLAPSE_GROUP_TREE: {
      const groupId = action.data;
      const { items: groups } = state;

      const updatedGroups = updateNested(groups, groupId, { expanded: false });

      return {
        ...state,
        items: updatedGroups,
        itemsById: getItemsById(updatedGroups),
      };
    }
    case UNAUTHORIZED:
      return groupsState;
    default:
      return state;
  }
};

const initialGroup = {
  loading: false,
  error: null,
  found: false,
  submitting: false,
  submitted: false,

  name: "",
  description: "",
  parent: null,
  topics: [],
  leaders: [],
  trainings: [],
};

const mergeFirstParent = (group) => {
  if (!group?.parents?.length) return group;
  return {
    ...group,
    parent: {
      ...group.parents[0],
      parents: group.parents.slice(1),
    },
  };
};

const currentGroupReducer = (state = initialGroup, action) => {
  switch (action.type) {
    case constants.GROUP.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.GROUP.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: action.data,
      };
    case constants.GROUP.SUCCESS: {
      let group = action.data;

      group = mergeFirstParent(group);

      return {
        ...state,
        ...group,
        found: true,
        loading: false,
      };
    }
    case constants.GROUP_SUBMIT.REQUEST: {
      return {
        ...state,
        error: null,
        submitting: true,
        submitted: false,
      };
    }
    case constants.GROUP_SUBMIT.SUCCESS: {
      return {
        ...state,
        submitting: false,
        submitted: true,
      };
    }
    case constants.GROUP_SUBMIT.FAILURE: {
      return {
        ...state,
        submitted: true,
        error: action.data,
        submitting: false,
      };
    }
    case constants.GROUP_RESET:
      return {
        ...initialGroup,
        found: false,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialGroup;
    default:
      return state;
  }
};

const parentGroupReducer = (state = initialGroup, action) => {
  switch (action.type) {
    case constants.PARENT_GROUP.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.PARENT_GROUP.FAILURE:
      return {
        ...initialGroup,
        loading: false,
        found: false,
        error: true,
      };
    case constants.PARENT_GROUP.SUCCESS: {
      const group = action.data;
      return {
        ...initialGroup,
        ...group,
        found: true,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return initialGroup;
    default:
      return state;
  }
};

const defaultGroupReducer = (state = initialGroup, action) => {
  switch (action.type) {
    case constants.DEFAULT_GROUP.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.DEFAULT_GROUP.FAILURE:
      return {
        ...initialGroup,
        loading: false,
        found: false,
        error: true,
      };
    case constants.DEFAULT_GROUP.SUCCESS: {
      const group = action.data;
      return {
        ...initialGroup,
        ...group,
        found: true,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return initialGroup;
    default:
      return state;
  }
};

const extraPaginationReducer = (state, action) => {
  switch (action.type) {
    case constants.PAGINATION.SET_MAX_LEVEL: {
      return {
        ...state,
        page: 0,
        max_level: action.data,
      };
    }
    default:
      return state;
  }
};

const basePaginationReducer = pagination(constants.PAGINATION);

const paginationReducer = reduceReducers(
  {
    page: 0,
    pagesize: 10,
    search: "",
    extra_filters: [],
    orderby: "name",
    max_level: 1,
  },
  basePaginationReducer,
  extraPaginationReducer,
);

export default combineReducers({
  list: makeListReducer(groupsReducer, "groups"),
  pagination: paginationReducer,
  currentParent: parentGroupReducer,
  defaultGroup: defaultGroupReducer,
  currentGroup: combineReducers({
    group: currentGroupReducer,
    users: groupUsersReducer,
    topics: groupTopicsReducer,
    trainings: groupTrainingsReducer,
  }),
});
