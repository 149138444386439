import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/groups/groupUsers.constants";
import { combineReducers } from "redux";

import pagination from "../pagination";
import { getItemsById } from "../utils";

const groupUsersState = {
  loading: false,
  ready: false,
  error: false,

  items: [],
  itemsById: {},
  count: 0,
  hasMore: false,
  itemsAdded: [],
  itemsRemoved: [],
};

const groupUsersReducer = (state = groupUsersState, action) => {
  switch (action.type) {
    case constants.GROUP_USERS_RESET:
      return {
        ...groupUsersState,
      };
    case constants.GROUP_USERS.REQUEST:
    case constants.GROUP_USERS_MORE.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
        error: false,
      };
    case constants.GROUP_USERS.FAILURE:
    case constants.GROUP_USERS_MORE.FAILURE:
      return {
        ...state,
        loading: false,
        ready: false,
        error: true,
        hasMore: false,
        items: [],
        itemsById: {},
      };
    case constants.GROUP_USERS.SUCCESS: {
      const { count, next } = action.data;
      let { results: users } = action.data;
      const { itemsAdded, itemsRemoved } = state;
      const addedIds = itemsAdded.map((it) => it.id);
      const removedIds = itemsRemoved.map((it) => it.id);
      const hasMore = next !== null;

      users = users.map((it) => {
        if (addedIds.includes(it.id)) {
          it.member = true;
        } else if (removedIds.includes(it.id)) {
          it.member = false;
        }
        return it;
      });

      return {
        ...state,
        items: users,
        itemsById: getItemsById(users),
        count: count,
        loading: false,
        ready: true,
        error: false,
        hasMore,
      };
    }
    case constants.GROUP_USERS_STORE_ADD: {
      const user = action.data;
      const { items, itemsAdded, itemsRemoved } = state;

      const updatedItems = items.map((it) => {
        if (it.id === user.id) {
          it.member = true;
        }
        return it;
      });

      return {
        ...state,
        items: updatedItems,
        itemsById: getItemsById(updatedItems),
        itemsAdded: [...itemsAdded, { ...user, member: true }],
        itemsRemoved: itemsRemoved.filter((it) => it.id !== user.id),
      };
    }
    case constants.GROUP_USERS_STORE_REMOVE: {
      const user = action.data;
      const { items, itemsAdded, itemsRemoved } = state;

      const updatedItems = items.map((it) => {
        if (it.id === user.id) {
          it.member = false;
        }
        return it;
      });

      return {
        ...state,
        items: updatedItems,
        itemsById: getItemsById(updatedItems),
        itemsAdded: itemsAdded.filter((it) => it.id !== user.id),
        itemsRemoved: [...itemsRemoved, { ...user, member: false }],
      };
    }
    case constants.GROUP_USERS_MORE.SUCCESS: {
      const { count, next } = action.data;
      let { results: users } = action.data;
      const { itemsAdded, itemsRemoved } = state;
      const addedIds = itemsAdded.map((it) => it.id);
      const removedIds = itemsRemoved.map((it) => it.id);

      const hasMore = next !== null;

      users = users.map((it) => {
        if (addedIds.includes(it.id)) {
          it.member = true;
        } else if (removedIds.includes(it.id)) {
          it.member = false;
        }
        return it;
      });

      const itemsById = getItemsById(users);

      return {
        ...state,
        count,
        hasMore,
        items: [...state.items, ...users],
        itemsById: { ...state.itemsById, itemsById },
        loading: false,
        ready: true,
        error: false,
      };
    }
    case constants.GROUP_USERS_ADD.SUCCESS: {
      return {
        ...state,
        itemsAdded: [],
      };
    }
    case constants.GROUP_USERS_REMOVE.SUCCESS: {
      return {
        ...state,
        itemsRemoved: [],
      };
    }
    case UNAUTHORIZED:
      return groupUsersState;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "-member",
  pagesize: 25,
});

export default combineReducers({
  list: groupUsersReducer,
  pagination: paginationReducer,
});
