import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/webhooks.constants";
import { combineReducers } from "redux";

import pagination from "./pagination";
import { getItemsById } from "./utils";
import { makeListReducer } from "./list";



const initialWebhooks = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  num_failures: 0,
  columns: {
    name: true,
    event_names: true,
    url_callback: true,
    num_failures: true,
    enabled: true,
  },
};

const webhooksReducer = (state = initialWebhooks, action) => {
  switch (action.type) {
    case constants.WEBHOOK.REQUEST:
    case constants.WEBHOOKS.REQUEST:
    case constants.WEBHOOK_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WEBHOOKS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        num_failures: state.num_failures + 1,
        loading: false,
      };
    case constants.WEBHOOKS.SUCCESS: {
      let { count, results: webhooks } = action.data;
      return {
        ...state,
        items: webhooks,
        itemsById: getItemsById(webhooks),
        num_failures: 0,
        count: count,
        loading: false,
      };
    }
    case constants.WEBHOOK.SUCCESS:
    case constants.WEBHOOK_RESET:
    case constants.WEBHOOK_UPDATE.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialWebhooks;
    default:
      return state;
  }
};


const initialWebhook = {
  id: null,
  name: "",
  enabled: true,
  url_callback: null,
  event_names: [],
  loading: false,
  error: false,
  found: false,
};

export const currentWebhook = (state = initialWebhook, action) => {
  switch (action.type) {
    case constants.WEBHOOK_CREATE.REQUEST:
    case constants.WEBHOOK_DELETE.REQUEST:
    case constants.WEBHOOK_NOTIFY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.WEBHOOK.SUCCESS: {
      let { count, results: webhooks } = action.data;
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    }
    case constants.WEBHOOK_CREATE.SUCCESS:
      return {
        ...state,
        loading: false,
        num_failures: 0
      };    
    case constants.WEBHOOK_DELETE.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.WEBHOOK_NOTIFY.SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case constants.WEBHOOK_NOTIFY.FAILURE: {
      let { count, results: webhooks } = action.data;
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    }
    case constants.WEBHOOK.FAILURE:
    case constants.WEBHOOK_CREATE.FAILURE:
    case constants.WEBHOOK_UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: true,
      };
    case constants.WEBHOOK_DELETE.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: true,
      };
    case constants.WEBHOOK_RESET:
      return {
        ...initialWebhook,
        found: false,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialWebhook;
    default:
      return state;
  }
};


export default combineReducers({
  list: makeListReducer(webhooksReducer, "webhooks"),
  current: currentWebhook,
  pagination: pagination(constants.PAGINATION, {
    orderby: "callback_url",
    extra_filters: [],
  }),
});