import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/introduction.constants";
import { combineReducers } from "redux";

import pagination from "./pagination";
import { mergeEditableField, mergeTypeField } from "./topics";
import { getItemsById } from "./utils";
import {makeListReducer} from "./list";

const initialListState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  isPanelShown: false,
  allSelected: false,
  anySelected: false,
  showAutoTranslateModal: false,
  columns: {
    title: true,
    thumbnail: true,
    status: true,
  },
};

const listReducer = (state = initialListState, action) => {
  switch (action.type) {
    case constants.INTRODUCTION.REQUEST:
    case constants.INTRODUCTION_DELETE.REQUEST:
    case constants.INTRODUCTION_PUBLISH.REQUEST:
    case constants.INTRODUCTION_UNPUBLISH.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INTRODUCTION.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
        timestamp: null,
      };
    case constants.INTRODUCTION.SUCCESS: {
      let { count, results: items, timestamp } = action.data;
      items = items.map(mergeEditableField);
      items = items.map(mergeTypeField);

      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
        timestamp,
      };
    }
    case constants.INTRODUCTION_DELETE.SUCCESS:
    case constants.INTRODUCTION_PUBLISH.SUCCESS:
    case constants.INTRODUCTION_UNPUBLISH.SUCCESS:
    case constants.INTRODUCTION_DELETE.FAILURE:
    case constants.INTRODUCTION_PUBLISH.FAILURE:
    case constants.INTRODUCTION_UNPUBLISH.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case constants.SELECT: {
      let { items } = state;
      items = items.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        allSelected: items.every((it) => it.selected === true),
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items, allSelected } = state;

      allSelected = !allSelected;
      items = items.map((it) => {
        it.selected = allSelected;
        return it;
      });

      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        allSelected,
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case constants.AUTO_TRANSLATE_MODAL.SHOW: {
      return {
        ...state,
        showAutoTranslateModal: true,
      };
    }
    case constants.AUTO_TRANSLATE_MODAL.HIDE: {
      return {
        ...state,
        showAutoTranslateModal: false,
      };
    }
    case UNAUTHORIZED:
      return initialListState;
    default:
      return state;
  }
};

const initialItemState = {
  found: false,
  error: false,
  loading: false,

  title_html: "",
  text_html: "",
  type: "image",
  image: null,
};

const itemReducer = (state = initialItemState, action) => {
  switch (action.type) {
    case constants.INTRODUCTION_ITEM_CREATE.REQUEST:
    case constants.INTRODUCTION_ITEM.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.INTRODUCTION_ITEM_CREATE.FAILURE:
    case constants.INTRODUCTION_ITEM.FAILURE:
      return {
        ...state,
        found: false,
        error: true,
        loading: false,
      };
    case constants.INTRODUCTION_ITEM_CREATE.SUCCESS:
    case constants.INTRODUCTION_ITEM.SUCCESS: {
      let introduction = action.data;
      introduction = mergeEditableField(introduction);
      introduction = mergeTypeField(introduction);

      return {
        ...state,
        ...introduction,
        found: true,
        error: false,
        loading: false,
      };
    }
    case constants.INTRODUCTION_ITEM_RESET:
      return {
        ...initialItemState,
        found: false,
        error: false,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialItemState;
    default:
      return state;
  }
};

const initialLocalizationListState = {
  loading: false,
  error: false,
  items: [],
  itemsById: {},
};

const localizationReducer = (state = initialLocalizationListState, action) => {
  switch (action.type) {
    case constants.TRANSLATIONS.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
      };
    case constants.TRANSLATIONS.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        ready: false,
      };
    case constants.TRANSLATIONS.SUCCESS: {
      let items = action.data;

      // Sort items in the way so base introduction is always first
      items.sort((a, b) => (a.is_base ? -1 : b.is_base ? 1 : 0));
      const baseTranslation = items.find((it) => it.is_base);
      items = items.map((it) => {
        return {
          ...it,
          use_base_image:
            !it.is_base &&
            Boolean(it.image) &&
            it.image === baseTranslation.image,
          type: it.type ? it.type : (it?.video ? "video" : it?.image ? "image" : "text")
        };
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        loading: false,
        ready: true,
      };
    }
    case constants.TRANSLATIONS_RESET: {
      return {
        ...initialLocalizationListState,
      };
    }
    case UNAUTHORIZED:
      return initialLocalizationListState;
    default:
      return state;
  }
};

const currentMeta = (state = initialItemState, action) => {
  switch (action.type) {
    case constants.META_INTRODUCTION_CREATE.REQUEST:
    case constants.META_INTRODUCTION.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case constants.META_INTRODUCTION_CREATE.SUCCESS:
    case constants.META_INTRODUCTION.SUCCESS: {
      let introduction = action.data;
      introduction = mergeEditableField(introduction);
      introduction = mergeTypeField(introduction);

      return {
        ...state,
        ...introduction,
        found: true,
        loading: false,
        error: false,
      };
    }
    case constants.META_INTRODUCTION_CREATE.FAILURE:
    case constants.META_INTRODUCTION.FAILURE: {
      return {
        ...initialItemState,
        found: false,
        loading: false,
        error: true,
      };
    }
    case constants.META_INTRODUCTION_RESET: {
      return {
        ...initialItemState,
      };
    }
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "-updated",
});

export default combineReducers({
  list: makeListReducer(listReducer, "introduction"),
  pagination: paginationReducer,
  current: combineReducers({
    introduction: itemReducer,
    meta: currentMeta,
    translations: combineReducers({
      list: localizationReducer,
    }),
  }),
});
