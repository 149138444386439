import { combineReducers } from "redux";

import * as constants from "../constants/activityLog.constants.js";
import list from "./list";
import pagination from "./pagination";

export default combineReducers({
  list: list(constants.ACTIVITY_LOG, {
    columns: {
      activity: true,
      created: true,
      user: true,
    },
  }),
  pagination: pagination(constants.ACTIVITY_LOG_PAGINATION, {
    orderby: "-created",
  }),
});
