import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/users.constants";
import { combineReducers } from "redux";

import { getRole } from "../userRoles";
import pagination from "./pagination";
import { getItemsById } from "./utils";
import {makeListReducer} from "./list";

const initialState = {
  items: [],
  itemsById: {},
  count: 0,
  loading: false,
  allSelected: false,
  anySelected: false,
  columns: {
    firstname: true,
    lastname: true,
    email: true,
    learningProgress: true,
    trainingTime: true,
    certificationStatus: true,
    lastSeenOnline: true,
    moderator: false,
    disabled: false,
    invitationDate: false,
    invitationStatus: false,
    sso: false,
  }
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.USERS.REQUEST:
      return {
        ...state,
        loading: true,
        allSelected: false,
        anySelected: false,
      };
    case constants.USERS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.USERS.SUCCESS: {
      const { count, results: users } = action.data;
      return {
        ...state,
        items: users,
        itemsById: getItemsById(users),
        count: count,
        loading: false,
      };
    }
    case constants.SELECT: {
      let { items: users } = state;
      users = users.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items: users,
        itemsById: getItemsById(users),
        allSelected: users.every((it) => it.selected === true),
        anySelected: users.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items: users, allSelected } = state;
      allSelected = !allSelected;
      users = users.map((it) => {
        it.selected = allSelected;
        return it;
      });
      return {
        ...state,
        items: users,
        itemsById: getItemsById(users),
        allSelected,
        anySelected: users.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);
      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case constants.USER_ENABLE.REQUEST:
    case constants.USER_DISABLE.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.USER_ENABLE.SUCCESS:
    case constants.USER_ENABLE.FAILURE:
    case constants.USER_DISABLE.SUCCESS:
    case constants.USER_DISABLE.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.USER_CERTS_INFO.SUCCESS: {
      const { userId, info } = action.data;
      if (!state.itemsById[userId]) return state;
      let { items: users } = state;
      users = users.map((it) => {
        if (it.id === userId) return { ...it, certificateInfo: info };
        return it;
      });
      return {
        ...state,
        items: users,
        itemsById: getItemsById(users),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const allUsersState = {
  loading: false,
  items: [],
  itemsById: [],
};

const allUsersReducer = (state = allUsersState, action) => {
  switch (action.type) {
    case constants.USERS_ALL.REQUEST:
      return {
        ...state,
        loading: true,
        ready: false,
        error: false,
      };
    case constants.USERS_ALL.FAILURE:
      return {
        ...state,
        loading: false,
        ready: false,
        error: true,
        items: [],
        itemsById: {},
      };
    case constants.USERS_ALL.SUCCESS: {
      const { count, results: users } = action.data;
      return {
        ...state,
        allSelected: false,
        anySelected: false,
        items: users,
        itemsById: getItemsById(users),
        count: count,
        loading: false,
        ready: true,
        error: false,
      };
    }
    case UNAUTHORIZED:
      return allUsersState;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "email",
  extra_filters: [],
});

const initialUserState = {
  found: false,
  loading: false,
  submitted: false,
  error: false,

  groups_ready: false,
  certs_ready: false,
  roles: [],
  groups: [],
  certificates: [],
  trainings: [],
  compliance: [],
  statistics: {},
  confirmationLink: null,
};

const currentUserReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case constants.USER.RESET:
      return {
        ...initialUserState
      };
    case constants.USER_UPDATE.REQUEST:
    case constants.USER.REQUEST:
      return {
        ...state,
        submitted: false,
        loading: true,
      };
    case constants.USER.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: true,
      };
    case constants.USER.SUCCESS: {
      const user = action.data;
      return {
        ...state,
        ...user,
        role: getRole(user),
        found: true,
        loading: false,
      };
    }
    case constants.USER_UPDATE.SUCCESS: {
      const user = action.data;

      return {
        ...state,
        ...user,
        role: getRole(user),
        loading: false,
        submitted: true,
      };
    }
    case constants.USER_UPDATE.FAILURE: {
      const error = action.data;

      return {
        ...state,
        error,
        submitted: true,
        loading: false,
      };
    }
    case constants.USER_CONFIRMATION_REQUEST.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.USER_CONFIRMATION_REQUEST.SUCCESS: {
      const { link } = action.data;

      return {
        ...state,
        confirmationLink: link,
        loading: false,
      };
    }
    case constants.USER_CONFIRMATION_REQUEST.FAILURE: {
      return {
        ...state,
        confirmationLink: null,
        loading: false,
      };
    }
    case constants.USER_PASSWORD_RESET.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case constants.USER_PASSWORD_RESET.SUCCESS: {
      const { link } = action.data;

      return {
        ...state,
        passwordResetLink: link,
        loading: false,
      };
    }
    case constants.USER_PASSWORD_RESET.FAILURE: {
      return {
        ...state,
        passwordResetLink: null,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return initialUserState;
    default:
      return state;
  }
};

export default combineReducers({
  list: makeListReducer(usersReducer, "users"),
  pagination: paginationReducer,
  currentUser: currentUserReducer,
  all: allUsersReducer,
});
