import { applyMiddleware, compose, createStore } from "redux";
import { logger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import {thunk} from "redux-thunk";

import reducers from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [],
  version: 1.3,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const configureStore = (initialState = {}) => {
  let middleware = [thunk];
  if (process.env.NODE_ENV === "development") {
    middleware = [...middleware, logger];
  }

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // noinspection JSCheckFunctionSignatures
  const makeStore = composeEnhancers(applyMiddleware(...middleware))(
    createStore,
  );
  const store = makeStore(persistedReducer, initialState);
  const persistor = persistStore(store);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () =>
      store.replaceReducer(persistedReducer),
    );
  }

  return { store, persistor };
};

export default configureStore;
