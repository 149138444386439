import axios from "lib/axios";

export const getCurrentUser = () => {
  return axios.get(`/auth/info`);
};

export const updateCurrentUser = (data) => {
  return axios.put(`/auth/info`, data);
};

export const login = (email, password, rememberMe = false) => {
  return axios.post(`/auth/login`, {
    email,
    password,
    remember_me: rememberMe,
  });
};

export const logout = () => {
  return axios.post(`/auth/logout`);
};

export const forgotPassword = (email) => {
  return axios.post(`/auth/reset-password`, { email });
};

export const resendConfirmationRequest = (email) => {
  return axios.post(`/auth/resend-confirmation-request`, { email });
};

export const getLoginProviders = () => {
  return axios.get(`/auth/get-login-providers`);
};

export const getLoginProviderURL = (provider) => {
  return axios.get(`/auth/get-login-url`, {
    params: {
      provider,
    },
  });
};

export const getSubdomainInfo = (subdomain) => {
  return axios.get(`/subdomains/info/${subdomain}`);
};

export const getModeratedCompanies = (params) => {
  return axios.get(`/auth/moderated`, {
    params,
  });
};

export const getMfaSetup = () => {
  return axios.get(`/auth/mfa/setup`);
};

export const authenticateMFA = (data) => {
  return axios.post(`/auth/mfa`, data);
};

export const getApiKey = () => {
  return axios.get(`/auth/keys`);
};

export const createApiKey = (data) => {
  return axios.post(`/auth/keys`, data);
};

export const deleteApiKey = () => {
  return axios.delete(`/auth/keys`);
};

export const getApiConfig = () => {
  return axios.get(`/auth/api-config`);
};
