import axios from "lib/axios";

export const getCompany = () => {
  return axios.get(`/info/company`);
};

export const updateCompany = (data) => {
  return axios.put(`/info/company`, data);
};

export const updateCompanyWhiteLabeling = (formData) => {
  return axios.postForm(`/info/company/white_labeling`, formData);
};
