import { combineReducers } from "redux";

import { UNAUTHORIZED } from "../constants/auth.constants";
import * as constants from "../constants/signupURLs.constants";
import pagination from "./pagination";
import { getItemsById } from "./utils";
import {createUrl} from "../utils";
import {makeListReducer} from "./list";

const getSignupURL = (code) => {
  const {protocol, host} = new URL(window.location);
  const path = createUrl(`/signup/${code}`);
  return `${protocol}//${host}${path}`;
};

const initialSignupURLs = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  allSelected: false,
  anySelected: false,
  columns: {
    link: true,
    groups: true,
    created: true,
  },
  isPanelShown: false,
};

const signupURLsReducer = (state = initialSignupURLs, action) => {
  switch (action.type) {
    case constants.SIGNUP_URLS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.SIGNUP_URLS.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.SIGNUP_URLS.SUCCESS: {
      const { count, results: signupURLs } = action.data;
      const items = signupURLs.map((it) => {
        return { ...it, signupURL: getSignupURL(it.code) };
      });

      return {
        ...state,
        allSelected: false,
        anySelected: false,
        items: items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
      };
    }
    case constants.SELECT: {
      let { items } = state;

      items = items.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });

      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        allSelected: items.every((it) => it.selected === true),
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items, allSelected } = state;
      allSelected = !allSelected;
      items = items.map((it) => {
        it.selected = allSelected;
        return it;
      });

      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        allSelected,
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialSignupURLs;
    default:
      return state;
  }
};

const initialSignupURL = {
  loading: false,
  error: false,
  found: false,
  groups: [],
};

const currentSignupURL = (state = initialSignupURL, action) => {
  switch (action.type) {
    case constants.SIGNUP_URL.REQUEST:
    case constants.SIGNUP_URL_CREATE.REQUEST:
    case constants.SIGNUP_URL_UPDATE.REQUEST:
    case constants.SIGNUP_URL_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.SIGNUP_URL.FAILURE:
    case constants.SIGNUP_URL_CREATE.FAILURE:
    case constants.SIGNUP_URL_UPDATE.FAILURE:
    case constants.SIGNUP_URL_DELETE.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: true,
      };
    case constants.SIGNUP_URL_CREATE.SUCCESS:
    case constants.SIGNUP_URL_UPDATE.SUCCESS:
    case constants.SIGNUP_URL.SUCCESS: {
      const item = action.data;
      return {
        ...state,
        ...item,
        signupURL: getSignupURL(item.code),
        found: true,
        loading: false,
      };
    }
    case constants.SIGNUP_URL_DELETE.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.SIGNUP_URL_RESET:
      return {
        ...initialSignupURL,
        found: false,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialSignupURL;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "created",
});

const initialSignupURLConfig = {
  loading: false,
  error: false,
  found: false,
  allowed_domains: [],
  signup_notifications: {
    day: null,
    frequency: null,
    hour: null,
  },
};

const signupURLConfigReducer = (state = initialSignupURLConfig, action) => {
  switch (action.type) {
    case constants.SIGNUP_URL_CONFIG.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.SIGNUP_URL_CONFIG.FAILURE:
      return {
        ...state,
        loading: false,
        found: false,
        error: true,
      };
    case constants.SIGNUP_URL_CONFIG.SUCCESS: {
      const item = action.data;
      return {
        ...state,
        ...item,
        found: true,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return initialSignupURL;
    default:
      return state;
  }
};

export default combineReducers({
  list: makeListReducer(signupURLsReducer, "signupURLs"),
  pagination: paginationReducer,
  current: currentSignupURL,
  settings: signupURLConfigReducer,
});
