import axios from "lib/axios";

export const getDashboards = () => {
  return axios.get(`/dashboards`);
};

export const createDashboard = (data) => {
  return axios.post(`/dashboards/`, data);
};

export const updateDashboard = (dashboardId, data) => {
  return axios.put(`/dashboards/${dashboardId}`, data);
};

export const deleteDashboard = (dashboardId) => {
  return axios.delete(`/dashboards/${dashboardId}`);
};
