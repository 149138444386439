import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/reporting.constants";
import { combineReducers } from "redux";

import pagination from "./pagination";
import { getItemsById } from "./utils";
import {Task} from "../models/task";
import {makeListReducer} from "./list";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  allSelected: false,
  anySelected: false,
  columns: {
    name: true,
    report: true,
    users: true,
    frequency: true,
    enabled: true,
  },
};

const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.REPORT_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.REPORT_LIST.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.REPORT_LIST.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
      };
    }
    case constants.SELECT: {
      let { items } = state;

      items = items.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected: items.every((it) => it.selected === true),
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items, allSelected } = state;

      allSelected = !allSelected;
      items = items.map((it) => {
        it.selected = allSelected;
        return it;
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected,
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case constants.REPORT_LIST_ITEM_UPDATE.REQUEST: {
      let ids = action.data;
      if (!Array.isArray(ids)) ids = [ids];

      let { items } = state;

      items = items.map(it => ({
        ...it,
        loading: ids.includes(it.id)
      }));

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
      }
    }
    case constants.REPORT_LIST_ITEM_UPDATE.FAILURE:
    case constants.REPORT_LIST_ITEM_UPDATE.SUCCESS: {
      let { items } = state;

      items = items.map(it => ({
        ...it,
        loading: false
      }));

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
      }
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "-created",
});

const getInitialState = () => {
  const task = new Task();

  return {
    loading: false,
    error: false,
    found: false,
    ...task.json()
  }
}

const currentReportReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case constants.REPORT_ITEM.REQUEST:
    case constants.REPORT_ITEM_CREATE.REQUEST:
    case constants.REPORT_ITEM_UPDATE.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }
    case constants.REPORT_ITEM.SUCCESS:
    case constants.REPORT_ITEM_CREATE.SUCCESS:
    case constants.REPORT_ITEM_UPDATE.SUCCESS: {
      const data = action.data;

      const task = new Task(data);
      return {
        ...state,
        ...task.json(),
        found: true,
        loading: false,
      }
    }
    case constants.REPORT_ITEM.FAILURE:
    case constants.REPORT_ITEM_CREATE.FAILURE:
    case constants.REPORT_ITEM_UPDATE.FAILURE: {
      return {
        ...state,
        found: false,
        loading: false,
        error: true
      }
    }
    case UNAUTHORIZED:
    case constants.REPORT_ITEM.RESET:
      return getInitialState();
    default:
      return state
  }
}


export default combineReducers({
  list: makeListReducer(listReducer, "reporting"),
  pagination: paginationReducer,
  current: combineReducers({
    report: currentReportReducer,
  }),
});
