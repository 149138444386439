import axios from "lib/axios";

export const getQuestions = (topicId, params) => {
  //TODO: filter_tags
  return axios.get(`/categories/${topicId}/questions`, {
    params,
  });
};

export const getQuestion = (questionId) => {
  return axios.get(`/questions/${questionId}`);
};

export const getQuestionTranslations = (questionId) => {
  return axios.get(`/questions/${questionId}/translations`);
};

export const updateQuestion = (questionId, formData) => {
  return axios.putForm(`/questions/${questionId}`, formData);
};

export const translateQuestion = (questionId, language) => {
  return axios.post(`/questions/${questionId}/auto-translate/${language}`);
};

export const bulkTranslateQuestions = (questionIds, languages) => {
  return axios.post(`/questions/bulk/auto-translate`, {
    ids: questionIds,
    languages,
  });
};

export const patchQuestion = (questionId, formData) => {
  return axios.patchForm(`/questions/${questionId}`, formData);
};

export const createQuestion = (topicId, formData) => {
  return axios.postForm(`/categories/${topicId}/questions`, formData);
};

export const createMultiLanguageQuestion = (topicId, formData) => {
  return axios.postForm(`/meta_categories/${topicId}/questions`, formData);
};

export const publishQuestion = (questionId) => {
  return axios.put(`/questions/${questionId}/publish`);
};

export const publishMetaQuestion = (questionId) => {
  return axios.put(`/meta_questions/${questionId}/publish`);
};

export const unpublishQuestion = (questionId) => {
  return axios.put(`/questions/${questionId}/unpublish`);
};

export const unpublishMetaQuestion = (questionId) => {
  return axios.put(`/meta_questions/${questionId}/unpublish`);
};

export const deleteQuestion = (questionId) => {
  return axios.delete(`/questions/${questionId}`);
};

export const getQuestionComments = (questionId, params) => {
  return axios.get(`/questions/${questionId}/comments`, { params });
};

export const createQuestionComment = (questionId, data) => {
  return axios.post(`/questions/${questionId}/comments`, data);
};

export const deleteQuestionComment = (questionId, commentId) => {
  return axios.delete(`/questions/${questionId}/comments/${commentId}`);
};

export const getMetaQuestionComments = (questionId, params) => {
  return axios.get(`/meta_questions/${questionId}/comments`, { params });
};

export const createMetaQuestionComment = (questionId, data) => {
  return axios.post(`/meta_questions/${questionId}/comments`, data);
};

export const deleteMetaQuestionComment = (questionId, commentId) => {
  return axios.delete(`/meta_questions/${questionId}/comments/${commentId}`);
};

export const bulkCopyQuestions = (questionIds, destinationTopic) => {
  return axios.post(`/questions/bulk/copy/${destinationTopic}`, {
    ids: questionIds,
  });
};

export const bulkMoveQuestions = (questionIds, destinationTopic) => {
  return axios.post(`/questions/bulk/move/${destinationTopic}`, {
    ids: questionIds,
  });
};

export const bulkCopyMultiLanguageQuestions = (
  questionIds,
  destinationTopic,
) => {
  return axios.post(`/meta_questions/bulk/copy/${destinationTopic}`, {
    ids: questionIds,
  });
};

export const bulkMoveMultiLanguageQuestions = (
  questionIds,
  destinationTopic,
) => {
  return axios.post(`/meta_questions/bulk/move/${destinationTopic}`, {
    ids: questionIds,
  });
};

export const bulkPublishQuestions = (questionIds) => {
  return axios.post(`/questions/bulk/publish`, { ids: questionIds });
};

export const bulkUnpublishQuestions = (questionIds) => {
  return axios.post(`/questions/bulk/unpublish`, { ids: questionIds, ...data });
};

export const bulkDeleteQuestions = (questionIds) => {
  return axios.post(`/questions/bulk/delete`, { ids: questionIds });
};

export const bulkAddTags = (data) => {
  return axios.post(`/questions/bulk/add_tags`, data);
};

export const bulkRemoveTags = (data) => {
  return axios.post(`/questions/bulk/rem_tags`, data);
};

export const bulkCreateQuestions = (topicId, data) => {
  return axios.post(`/meta_categories/${topicId}/questions/bulk`, data);
};
