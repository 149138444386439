import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/externalTraining/training.constants";
import { LIST_UPDATE } from "constants/externalTraining/training.constants";

import pagination from "../pagination";
import { getItemsById } from "../utils";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  allSelected: false,
  anySelected: false,
  columns: {
    name: true,
    num_users: true,
    // num_users_ready: true,
    num_invited: true,
    num_completed: true,
    tags: true,
  },
};

export const externalTrainingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LIST.REQUEST:
    case constants.LIST_UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.LIST.FAILURE:
    case constants.LIST_UPDATE.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.LIST.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
      };
    }
    case LIST_UPDATE.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case constants.SELECT: {
      let { items } = state;
      items = items.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected: items.every((it) => it.selected === true),
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items, allSelected } = state;

      allSelected = !allSelected;
      items = items.map((it) => {
        it.selected = allSelected;
        return it;
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected,
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

export const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "-created",
});

const externalTrainingInitState = {
  id: null,
  name: null,
  groups: [],
  loading: false,
  error: false,
  found: false,
};

export const externalTrainingReducer = (
  state = externalTrainingInitState,
  action,
) => {
  switch (action.type) {
    case constants.ITEM.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.ITEM.SUCCESS: {
      const data = action.data;

      return {
        ...state,
        ...data,
        error: false,
        found: true,
        loading: false,
      };
    }
    case constants.ITEM.FAILURE: {
      return {
        ...state,
        found: false,
        loading: false,
        error: true,
      };
    }
    case constants.ITEM_RESET: {
      return externalTrainingInitState;
    }
    default:
      return state;
  }
};
