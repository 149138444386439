import { UNAUTHORIZED } from "constants/auth.constants";
import * as constants from "constants/feedback.constants";
import { combineReducers } from "redux";

import pagination from "./pagination";
import { getItemsById } from "./utils";
import {makeListReducer} from "./list";

const initialState = {
  loading: false,
  items: [],
  itemsById: {},
  count: 0,
  allSelected: false,
  anySelected: false,
  columns: {
    firstname: true,
    lastname: true,
    email: true,
    title: true,
    text: true,
    status: true,
    created: true,
    responses: true,
  },
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FEEDBACK.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.FEEDBACK.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        loading: false,
      };
    case constants.FEEDBACK.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
        allSelected: false,
        anySelected: false,
      };
    }
    case constants.SELECT: {
      let { items } = state;
      items = items.map((it) => {
        if (it.id === action.data.id) {
          it.selected = !it.selected;
        }
        return it;
      });
      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected: items.every((it) => it.selected === true),
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SELECT_ALL: {
      let { items, allSelected } = state;

      allSelected = !allSelected;
      items = items.map((it) => {
        it.selected = allSelected;
        return it;
      });

      return {
        ...state,
        items,
        itemsById: getItemsById(items),
        allSelected,
        anySelected: items.some((it) => it.selected === true),
      };
    }
    case constants.SHOW_COLUMNS: {
      const names = action.data;
      const columns = Object.keys(state.columns);

      return {
        ...state,
        columns: columns.reduce(
          (obj, key) => ({ ...obj, [key]: names.includes(key) }),
          {},
        ),
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const currentFeedbackInitialState = {
  found: false,
  error: false,
  loading: false,
  user: {},
};

const currentFeedbackReducer = (
  state = currentFeedbackInitialState,
  action,
) => {
  switch (action.type) {
    case constants.FEEDBACK_ITEM.REQUEST:
    case constants.FEEDBACK_ITEM_UPDATE.REQUEST:
    case constants.FEEDBACK_ITEM_DELETE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.FEEDBACK_ITEM.FAILURE:
    case constants.FEEDBACK_ITEM_UPDATE.FAILURE:
    case constants.FEEDBACK_ITEM_DELETE.FAILURE:
      return {
        ...state,
        found: false,
        error: true,
        loading: false,
      };
    case constants.FEEDBACK_ITEM.SUCCESS:
    case constants.FEEDBACK_ITEM_UPDATE.SUCCESS: {
      const data = action.data;
      return {
        ...state,
        ...data,
        found: true,
        error: false,
        loading: false,
      };
    }
    case constants.FEEDBACK_ITEM_DELETE.SUCCESS:
      return {
        ...currentFeedbackInitialState,
        found: false,
        error: false,
        loading: false,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const paginationReducer = pagination(constants.PAGINATION, {
  orderby: "-created",
});

const initialResponsesState = {
  loading: false,
  error: false,
  items: [],
  itemsById: {},
  count: 0,
};

const feedbackResponsesReducer = (state = initialResponsesState, action) => {
  switch (action.type) {
    case constants.FEEDBACK_RESPONSES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.FEEDBACK_RESPONSES.FAILURE:
      return {
        ...state,
        items: [],
        itemsById: {},
        count: 0,
        error: true,
        loading: false,
      };
    case constants.FEEDBACK_RESPONSES.SUCCESS: {
      const { count, results: items } = action.data;
      return {
        ...state,
        items: items,
        itemsById: getItemsById(items),
        count: count,
        loading: false,
      };
    }
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};

const feedbackResponsesPagination = pagination(
  constants.FEEDBACK_RESPONSES_PAGINATION,
  {
    orderby: "-created",
    pagesize: 5,
  },
);

export default combineReducers({
  list: makeListReducer(feedbackReducer, "feedback"),
  pagination: paginationReducer,
  currentFeedback: combineReducers({
    feedback: currentFeedbackReducer,
    responses: combineReducers({
      list: feedbackResponsesReducer,
      pagination: feedbackResponsesPagination,
    }),
  }),
});
