const StatusCodes = {
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

const CSRF_ERROR_CODE = 103;

export const baseUrl = window.webapiUrl || "/webapi";

export const makeError = (name, data = {}) => Promise.reject({ name, ...data });

export const unauthorized = (message) => {
  return makeError("AuthError", { status: StatusCodes.UNAUTHORIZED, message });
};

export const clientError = (status, data = {}) =>
  makeError("ClientError", { status, ...data });

export const csrfError = (status, data = {}) =>
  makeError("CsrfError", { status, ...data });

export const unknownError = (status) => makeError("UnknownError", { status });

export const jsonOrReject = async (result) => {
  const { status } = result;

  if (status === StatusCodes.NO_CONTENT) {
    return {};
  }

  const responseJSON = await result.json();
  const { message } = responseJSON;

  if (result.ok) {
    return responseJSON;
  }

  if (status === StatusCodes.UNAUTHORIZED) {
    if (message?.code === CSRF_ERROR_CODE) {
      return csrfError(status, { message });
    }
    return unauthorized(responseJSON);
  }
  if (status === StatusCodes.BAD_REQUEST || status === StatusCodes.FORBIDDEN) {
    const { message } = responseJSON;
    return clientError(status, { message });
  }
  if (status === StatusCodes.NOT_FOUND) {
    return clientError(status, { message: {} });
  }
  return unknownError(status);
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatListParams = (
  { search, page, pagesize, orderby, extra_filters, ...rest },
  parsers = {},
) => {
  const params = new URLSearchParams();
  if (page && page !== 0) params.append("page", page);
  if (pagesize && pagesize !== 10) params.append("pagesize", pagesize);
  if (orderby) params.append("orderby", orderby);
  if (search) params.append("search", search);
  if (extra_filters && extra_filters.length) {
    params.append("extra_filters", extra_filters.join(","));
  }

  Object.keys(rest).forEach((item) => {
    let value = rest[item];
    if (Array.isArray(value) && !value.length) {
      return;
    }

    if (value) {
      if (parsers[item]) {
        const parseFunc = parsers[item];
        value = parseFunc(value);
      }
      params.append(item, value);
    }
  });

  return Array.from(params).length ? "?" + params.toString() : "";
};

export const fetchAll = async (func, args = {}) => {
  let page = 0;
  const pagesize = 100;
  let results = [];
  let response;

  do {
    response = await func({ ...args, page, pagesize });
    results = [...results, ...response.results];
    page += 1;
  } while (response?.next);

  return { ...response, results };
};
